import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import SectorPage from '../../components/sectorPage'

const TelecomPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/telecom" type="sector">
      <SEO 
        title="Classement des meilleurs sites téléphonie/internet - 2021"
        description="Quels sont les meilleurs sites de télécom de l'Observatoire de l'impact positif ? Analyse des opérateurs télécom et Internet français free, SFR, Bouygues et Orange."
        path="/2021/telecom"
        year="2021" />
      
      <Breadcrumb url="/2021/telecom" label="Telecom" year="2021" />

      <SectorPage slug="telecom" type="sector" year="2021" />
    </Layout>
  )
  }

export default TelecomPage

